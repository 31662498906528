/* ==========================================================================
Language flags light
========================================================================== */
.language--flags-light {
  .language--flags-link {
    color: $white;

    &:focus,
    &:hover {
      color: $theme-primary;
    }
  }

  .language--flags-divider {
    color: $white;
  }
}
