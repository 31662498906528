/* ==========================================================================
Footer links
========================================================================== */
.footer__links {
  @extend
    .py-1;

  background-color: rgba($black, 0.8);

  a {
    @include transition();

    color: $white;

    &:focus,
    &:hover {
      color: $theme-primary;
      text-decoration: none;
    }
  }

  ul {
    @extend
      .d-flex;
  }

  li {
    @extend
      .d-flex,
      .ml-2;

    line-height: 1.9;

    &:first-child {
      @extend
        .ml-0;
    }
  }
}
