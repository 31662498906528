/* ==========================================================================
Card default - Style 2
========================================================================== */
.card-default-2 {
  @extend .mb-3;

  border: 0;

  .card-img-top {
    border-radius: $card-border-radius;
  }

  .card-body {
    @extend
      .p-0,
      .pt-1;
  }

  .card-footer {
    @extend .p-0;

    background-color: transparent;
    border: 0;
  }

  .card-buttons {
    font-size: 0;

    .btn {
      width: 100%;
    }

    .btn + .btn {
      @extend
        .ml-sm-1,
        .mt-1,
        .mt-sm-0;
    }
  }
}

@include media-breakpoint-up(sm) {
  .card-default-2 {
    .card-buttons {
      .btn {
        width: auto;
      }
    }
  }
}
