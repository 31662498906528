/* ==========================================================================
Card container
========================================================================== */
.card--container {
  @extend
    .mb-5,
    .mb-lg-10;
}

/* ==========================================================================
Card text
========================================================================== */
.card--text {
  @extend
    .mb-3,
    .text-sm-center;

  h2 {
    @extend .mb-2;
  }

  p {
    @extend .mb-2;
  }
}

/* ==========================================================================
Card button
========================================================================== */
.card--button {
  @extend .mt-2;
}
