/* ==========================================================================
Language dropdown
========================================================================== */
@import "switch-language/_language-dropdown";

/* ==========================================================================
Language flags
========================================================================== */
@import "switch-language/_language-flags";
@import "switch-language/_language-flags-dark";
@import "switch-language/_language-flags-light";

/* ==========================================================================
Language list
========================================================================== */
@import "switch-language/_language-list";
@import "switch-language/_language-list-dark";
@import "switch-language/_language-list-light";
