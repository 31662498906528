/* ==========================================================================
Navbar-brand
========================================================================== */
.navbar-brand {
  @extend .m-0;
  @extend .p-0;

  img {
    height: 40px;
  }
}

@include media-breakpoint-up(lg) {
  .navbar-brand {
    img {
      height: 60px;
    }
  }
}
