/* ==========================================================================
Concrete5 css fixes for Bootstrap4
========================================================================== */
div.ccm-panel-content ul.nav,
div.ccm-panel-content menu {
  display: block;
}

#ccm-theme-grid-edit-mode-row-wrapper {
  display: flex;
  width: 100%;
}

/* ==========================================================================
Overrides when toolbar is visible
========================================================================== */
.ccm-toolbar-visible {
  body {
    padding-top: calc(#{$navbar-height-xs} + #{$ccm-toolbar-height});
  }

  @include media-breakpoint-up(lg) {
    body {
      padding-top: calc(#{$navbar-height-lg} + #{$ccm-toolbar-height});
    }
  }

  .navbar {
    top: $ccm-toolbar-height;
  }

  .navbar-collapse {
    top: calc(#{$navbar-height-xs} + #{$ccm-toolbar-height});
  }

  .hero--height-xxl {
    .row {
      min-height: calc(100vh - (#{$navbar-height-xs} + #{$ccm-toolbar-height})) !important;
    }

    .hero--image {
      img {
        height: calc(100vh - (#{$navbar-height-xs} + #{$ccm-toolbar-height})) !important;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    .hero--height-xxl {
      .row {
        min-height: calc(100vh - (#{$navbar-height-lg} + #{$ccm-toolbar-height})) !important;
      }

      .hero--image {
        img {
          height: calc(100vh - (#{$navbar-height-lg} + #{$ccm-toolbar-height})) !important;
        }
      }
    }
  }
}

.ccm-notification-help-launcher {
  top: 110px !important;
}

/* ==========================================================================
Overrides pagination
========================================================================== */
.ccm-pagination-wrapper {
  .pagination {
    border-radius: $border-radius;
    display: flex;
    margin: 0;

    li {
      @extend .page-item;

      &.active {
        @extend .active;
      }

      &.disabled {
        @extend .disabled;
      }

      a,
      span {
        @extend .page-link;

        > span {
          display: none;
        }
      }
    }
  }
}

/* ==========================================================================
ccm-panel-detail visible fix
========================================================================== */
body div.ccm-panel-detail {
  display: block;
}
