/* ==========================================================================
Typography
========================================================================== */
h1,
.h1 {
  @include font-size(26);

  color: $theme-secondary;
  font-family: $font-family-1;
  font-weight: 500;

  @include media-breakpoint-up(md) {
    @include font-size(36);
  }

  @include media-breakpoint-up(xl) {
    @include font-size(48);
  }
}

h2,
.h2 {
  @include font-size(24);

  color: $theme-secondary;
  font-family: $font-family-1;
  font-weight: 500;

  @extend .mb-2;

  @include media-breakpoint-up(md) {
    @include font-size(32);
  }

  @include media-breakpoint-up(xl) {
    @include font-size(48);
  }
}

h3,
.h3 {
  @include font-size(22);

  font-family: $font-family-1;
  font-weight: 700;

  @include media-breakpoint-up(md) {
    @include font-size(28);
  }

  @include media-breakpoint-up(xl) {
    @include font-size(40);
  }
}

h4,
.h4 {
  @include font-size(20);

  font-family: $font-family-1;
  font-weight: 700;

  @include media-breakpoint-up(md) {
    @include font-size(26);
  }

  @include media-breakpoint-up(xl) {
    @include font-size(32);
  }
}

h5,
.h5 {
  @include font-size(18);

  font-family: $font-family-1;
  font-weight: 700;

  @include media-breakpoint-up(md) {
    @include font-size(20);
  }

  @include media-breakpoint-up(xl) {
    @include font-size(24);
  }
}

h6,
.h6 {
  @include font-size(16);

  font-family: $font-family-1;
  font-weight: 700;
}

p {
  @include media-breakpoint-up(xl) {
    @include font-size(18);
  }
}

small {
  @include font-size(12);
}
