/* ==========================================================================
Footer
========================================================================== */
.footer {
  color: $white;

  p:not(.footer__title) {
    @extend
      .mb-0;

    color: $white;
  }

  ul {
    @extend
      .m-0,
      .p-0;

    list-style: none;
  }
}

/* ==========================================================================
Footer title
========================================================================== */
.footer__title {
  @extend
    .mb-2;

  @include font-size(18);

  font-weight: $font-weight-bold;
}
