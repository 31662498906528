/* ==========================================================================
Formidable
========================================================================== */
#ff_1 {
  @extend
    .col-md-8,
    .col-lg-6,
    .py-md-6;
}

.formidable {
  @extend
    .position-relative,
    .py-5,
    .py-md-10;

  @include make-row();

  color: $white;
  background-color: $theme-primary;
  justify-content: center;

  /* ==========================================================================
  Buttons
  ========================================================================== */
  .buttons {
    @extend
      .col,
      .mt-2;

    text-align: center;

    &::after {
      background-image: url("/packages/enorm_blackbox/themes/enorm/assets/images/bottom-line.svg");
      background-position: center;
      background-repeat: no-repeat;
      content: "";
      display: inline-block;
      height: 50px;
      margin-top: 20px;
      width: 100%;
    }

    .btn {
      @extend
        .btn-theme-secondary,
        .m-0;

      border: 2px solid $theme-secondary;

      &:hover,
      &:focus {
        background-color: transparent;
        border: 2px solid $theme-secondary;
      }
    }
  }

  /* ==========================================================================
  Elements
  ========================================================================== */
  div[class^="captcha"] {
    @extend .d-none;
  }

  input {
    @extend .my-0;

    color: $white;
    border-radius: 0;
    background-color: $theme-primary;
  }

  .input {
    @extend
      .mb-2,
      .position-relative;
  }

  label {
    @extend
      .d-block,
      .my-0;
  }

  .contact-7 {
    @extend .mb-4;

    text-align: center;
  }

  textarea {
    color: $white;
    height: 100px;
    resize: none;
    border-radius: 0;
    background-color: $theme-primary;

    @include media-breakpoint-up(lg) {
      height: 202px;
    }
  }

  .required {
    color: $white;
  }

  /* ==========================================================================
  Errors
  ========================================================================== */
  .text-danger {
    @extend .m-0;

    @include font-size(14);

    bottom: -20px;
    font-weight: 700;
    left: 0;
    line-height: 20px;
    position: absolute;
  }

  .has-error {
    label {
      color: $danger;
    }

    .error {
      @extend .is-invalid;
    }
  }

  label[for^="checkbox"] + .has-error,
  label[for^="radio"] + .has-error {
    .text-danger {
      @extend .d-none;
    }
  }

  /* ==========================================================================
  Loader
  ========================================================================== */
  // $loader-size: 60px;

  // .please_wait_loader {
  //   @extend .position-absolute;

  //   background-color: rgba($black, 0.75);
  //   border-radius: 5px;
  //   height: $loader-size;
  //   left: 50%;
  //   top: 50%;
  //   width: $loader-size;
  // }

  /* ==========================================================================
  Formidable column
  ========================================================================== */
  .formidable_column {
    @extend .py-0;

    @include make-col-ready();

    &.width-12 {
      @extend .col-12;
    }

    &.width-6 {
      @extend .col-md-6;
    }

    &.width-4 {
      @extend .col-md-4;
    }

    &.width-3 {
      @extend .col-md-3;
    }
  }

  /* ==========================================================================
  Formidable column inner
  ========================================================================== */
  .formidable_column_inner {
    .element {
      @include make-row();
    }
  }

  /* ==========================================================================
  Formidable message
  ========================================================================== */
  .formidable_message {
    @extend .d-none;
  }

  /* ==========================================================================
  Formidable row
  ========================================================================== */
  .formidable_row {
    clear: none;
    overflow: visible;

    .element {
      clear: none;
      overflow: visible;
    }
  }
}
