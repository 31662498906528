/* ==========================================================================
Card default - Style 1
========================================================================== */
.card-default-1 {
  @extend .mb-3;

  .card-buttons {
    font-size: 0;

    .btn {
      width: 100%;
    }

    .btn + .btn {
      @extend
        .ml-sm-1,
        .mt-1,
        .mt-sm-0;
    }
  }
}

@include media-breakpoint-up(sm) {
  .card-default-1 {
    .card-buttons {
      .btn {
        width: auto;
      }
    }
  }
}
