/* ==========================================================================
Language list
========================================================================== */
.language--list {
  span {
    padding: 0 5px;
  }
}

/* ==========================================================================
Language list list
========================================================================== */
.language--list-list {
  @extend
    .d-flex,
    .justify-content-center,
    .mb-0,
    .p-0;

  list-style: none !important;
}

/* ==========================================================================
Language list item
========================================================================== */
.language--list-item {
  @extend
    .d-flex,
    .align-items-center;

  &:last-child {
    .language--list-divider {
      @extend .d-none;
    }
  }
}

/* ==========================================================================
Language list link
========================================================================== */
.language--list-link {
  @include font-size(14px);
  @include transition();

  text-decoration: none;
  text-transform: uppercase;
}

/* ==========================================================================
Language list active
========================================================================== */
.language--list-active {
  font-weight: 700;
}
