/* ==========================================================================
Columns Two Alignment
========================================================================== */
.image--left {
  .row {
    @extend .justify-content-start;
  }

  .columns--content {
    @extend .order-lg-2;
  }

  .columns--image {
    background-position: left;
  }
}

.image--right {
  .row {
    @extend .justify-content-end;
  }

  .columns--image {
    background-position: right;
  }
}

/* ==========================================================================
Columns One
========================================================================== */
.columns--container {
  position: relative;
  background-color: $theme-primary;
  overflow: hidden;

  .row {
    display: flex;
    flex-wrap: wrap;
  }

  .columns--content {
    position: relative;

    .lace {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      opacity: 0.2;
    }
  }

  .columns--inner {
    @extend
      .px-2,
      .px-md-6,
      .py-6,
      .py-md-8,
      .py-xl-10;

    position: relative;
    color: $white;
    text-transform: uppercase;
    width: 100%;
    z-index: 2;

    h2 {
      color: $theme-secondary;
    }
  }

  .columns--button {
    @extend .mt-2;

    .btn {
      color: $white;
    }
  }

  .columns--images-wrapper {
    overflow: hidden;

    .columns--image {
      min-height: 300px;
      background-size: cover;
    }
  }
}

@include media-breakpoint-up(lg) {
  .columns--container {
    .columns--images-wrapper {
      .columns--image {
        min-height: auto;
        height: 100%;
        background-size: 82%;
        background-color: transparent;
        background-attachment: fixed;
        background-repeat: no-repeat;
      }
    }
  }
}

@include media-breakpoint-up(xxl) {
  .columns--container {
    .columns--images-wrapper {
      .columns--image {
        background-size: 65%;
      }
    }
  }
}
