/* ==========================================================================
Card image overlay - Style 2
========================================================================== */
.card-image-overlay-2 {
  @extend
    .mb-3,
    .w-100;

  border: 0;
  color: $body-color;
  min-height: $card-image-overlay-height-xs;
  position: relative;

  &::before {
    background-color: rgba($white, 75%);
    bottom: 0;
    border-radius: $card-border-radius;
    content: "";
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
  }

  .card-img {
    @extend .h-100;

    object-fit: cover;
    object-position: 50% 50%;
    position: absolute;
  }

  .card-img-overlay {
    bottom: auto;
    left: auto;
    position: relative;
    right: auto;
    top: auto;
    z-index: 2;
  }

  .card-buttons {
    font-size: 0;

    .btn {
      width: 100%;
    }

    .btn + .btn {
      @extend
        .ml-sm-1,
        .mt-1,
        .mt-sm-0;
    }
  }
}

@include media-breakpoint-up(sm) {
  .card-image-overlay-2 {
    min-height: $card-image-overlay-height-sm;

    .card-buttons {
      .btn {
        width: auto;
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .card-image-overlay-2 {
    min-height: $card-image-overlay-height-md;
  }
}

@include media-breakpoint-up(lg) {
  .card-image-overlay-2 {
    min-height: $card-image-overlay-height-lg;
  }
}

@include media-breakpoint-up(xl) {
  .card-image-overlay-2 {
    min-height: $card-image-overlay-height-xl;
  }
}

@include media-breakpoint-up(xxl) {
  .card-image-overlay-2 {
    min-height: $card-image-overlay-height-xxl;
  }
}
