/* ==========================================================================
Navbar collapse
========================================================================== */
.navbar-collapse {
  align-items: center;
  background-color: $theme-primary;
  bottom: 0;
  display: flex;
  justify-content: flex-end;
  left: 0;
  overflow-y: auto;
  position: fixed;
  right: 0;
  top: 0;
  transform: translateX(100%);
  visibility: hidden;

  @include transition();
}

.navbar--open {
  .navbar-collapse {
    transform: translateX(0);
    visibility: visible;
  }
}
