/* ==========================================================================
Modal
========================================================================== */
.modal-backdrop {
  opacity: 1 !important;
}

.modal-body {
  @extend
    .py-4,
    .px-2;

  color: $white;
  background-color: $theme-primary;
  text-align: center;

  .btn-white-outline {
    @extend .mb-2;

    color: $white;
    border: 2px solid $white;
    background-color: $theme-primary;
    width: 100%;

    &:hover,
    &:focus {
      color: $theme-primary;
      background-color: $white;
    }
  }
}

@include media-breakpoint-up(sm) {
  .modal-body {
    .btn-white-outline {
      width: 50%;
    }
  }
}
