/* ==========================================================================
Cocktails
========================================================================== */
.cocktails--container {
  @extend .py-lg-5;

  position: relative;
  color: $white;
  background-color: $theme-primary;

  .container {
    @extend
      .py-5,
      .py-md-10;

    text-align: center;
  }

  .cocktails--content {
    @extend
      .mb-4,
      .mb-lg-6;
  }

  .cocktails--hover {
    @extend .p-2;

    background-color: $theme-primary;
    border: 2px solid $white;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;

    @include transition();

    p {
      @extend .mb-0;

      @include font-size(13);
    }
  }

  .cocktails--item {
    @extend .mb-4;

    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .cocktails--title {
      @extend .mt-2;
    }

    &:hover,
    &:focus {
      .cocktails--hover {
        opacity: 1;
      }
    }
  }

  .cocktail--image {
    img {
      width: 90px;
      height: 90px;
      object-fit: contain;
    }
  }

  .cocktails--bottom {
    @extend
      .mt-4;
  }
}

@include media-breakpoint-up(md) {
  .cocktails--container {
    .cocktail--image {
      img {
        width: 140px;
        height: 140px;
      }
    }

    .cocktails--hover {
      p {
        @include font-size(16);
      }
    }
  }
}
