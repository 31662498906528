/* ==========================================================================
Page List Plus
========================================================================== */
.ccm-block-page-list-wrapper {
  .plp_form_loading {
    color: $theme-primary;
    display: none;
    height: 3rem;
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    width: 3rem;
    z-index: 2;
  }
}

/* ==========================================================================
ccm-block-page-list-form
========================================================================== */
.ccm-search-block-form {
  .btn {
    @extend .btn-theme-primary;
  }
}

/* ==========================================================================
ccm-block-page-list-header
========================================================================== */
.ccm-block-page-list-header {
  @extend .mb-2;

  h2 {
    @extend .mb-0;
  }
}

/* ==========================================================================
ccm-block-page-list-pages
========================================================================== */
.ccm-block-page-list-pages {
  div[class^="col"] {
    @extend .d-flex;
  }

  .card {
    @extend
      .flex-fill,
      .mb-2;
  }

  .card-title {
    @extend .h5;
  }

  .card-footer {
    .btn {
      @extend .btn-theme-primary;
    }
  }
}

/* ==========================================================================
ccm-block-page-list-pagination
========================================================================== */
.ccm-block-page-list-pagination {
  @extend .mt-2;
}
