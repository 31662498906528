/* ==========================================================================
Card horizontal - Style 1
========================================================================== */
.card-horizontal-1 {
  @extend .mb-3;

  position: relative;

  .row {
    @extend .h-100;
  }

  .card-buttons {
    font-size: 0;

    .btn {
      width: 100%;
    }

    .btn + .btn {
      @extend
        .ml-sm-1,
        .mt-1,
        .mt-sm-0;
    }
  }
}

@include media-breakpoint-up(sm) {
  .card-horizontal-1 {
    .card-buttons {
      .btn {
        width: auto;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .card-horizontal-1 {
    .card-img {
      height: 100%;
      object-fit: cover;
      object-position: 50% 50%;
      position: absolute;
    }
  }
}
