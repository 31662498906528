/* ==========================================================================
Html / Body
========================================================================== */
html,
body {
  overflow-x: hidden; /* Prevent scroll on narrow devices */
}

body {
  -webkit-font-smoothing: antialiased;
}

/* ==========================================================================
Main
========================================================================== */

/* ==========================================================================
ReCaptcha
========================================================================== */
.grecaptcha-badge {
  visibility: hidden;
}

/* ==========================================================================
Animate.css
========================================================================== */
.animate__animated {
  opacity: 0;
}

.observed {
  opacity: 1;
}
