/* ==========================================================================
Hamburger
========================================================================== */
.hamburger {
  @extend
    .d-inline-flex,
    .mr-1;

  position: relative;
  top: 0.25rem;
  z-index: $zindex-fixed;

  &:focus {
    outline: 0;
  }

  .hamburger-inner,
  .hamburger-inner::after,
  .hamburger-inner::before {
    background-color: $white;
  }

  &.is-active {
    .hamburger-inner,
    .hamburger-inner::after,
    .hamburger-inner::before {
      background-color: $theme-secondary;
    }
  }
}
