/* ==========================================================================
Fonts
========================================================================== */
@font-face {
  font-family: "adam.cg_proregular";
  src:
    url("../../fonts/adam.cg_pro-webfont.woff2") format("woff2"),
    url("../../fonts/adam.cg_pro-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
