/* stylelint-disable */

/* ==========================================================================
Letter-spacing mixin for values from Adobe XD
========================================================================== */
@mixin letter-spacing($letter-spacing) {
  letter-spacing: ($letter-spacing / 1000) * 1em;
}

/* stylelint-enable */
