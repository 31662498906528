/* ==========================================================================
Lazy loading
========================================================================== */
img.lazy {
  @include transition();

  opacity: 0;

  &.loaded,
  &.error {
    opacity: 1;
  }

  &:not([src]) {
    visibility: hidden;
  }
}
