/* ==========================================================================
Social
========================================================================== */
.social {
  @extend
    .my-2;

  position: fixed;
  right: 20px;
  bottom: 10%;
  z-index: 3;
}

/* ==========================================================================
Social list
========================================================================== */
.social__list {
  @extend
    .mb-0,
    .p-0;

  list-style: none;
}

/* ==========================================================================
Social item
========================================================================== */
.social__item {
  margin-bottom: 5px;
  width: 20px;

  img {
    width: 100%;
  }
}

/* ==========================================================================
Social link
========================================================================== */
.social__link {
  @extend
    .d-block;

  @include font-size(30);
  @include transition;

  color: $white;
  line-height: 1;
  width: 100%;

  &:focus,
  &:hover {
    color: $theme-secondary;
  }
}
