/* ==========================================================================
Language flags dark
========================================================================== */
.language--flags-dark {
  .language--flags-link {
    color: $body-color;

    &:focus,
    &:hover {
      color: $theme-primary;
    }
  }

  .language--flags-divider {
    color: $body-color;
  }
}
