/* ==========================================================================
Language dropdown
========================================================================== */
.language--dropdown {
  .dropdown-toggle {
    @extend
      .btn-theme-primary,
      .d-flex,
      .px-1;

    padding-bottom: 1rem;
    padding-top: 1rem;

    span {
      line-height: 1;
    }

    img {
      height: 16px;
      vertical-align: top;
    }

    i {
      @extend .pl-1;
    }

    &::after {
      @extend .d-none;
    }
  }

  .dropdown-menu {
    img {
      height: 15px;
    }
  }

  .dropdown-item {
    img {
      position: relative;
      top: -1px;
    }
  }
}
