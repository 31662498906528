/* ==========================================================================
Navbar
========================================================================== */
.navbar {
  @extend
    .fixed-top,
    .py-0;

  background-color: transparent;
  height: $navbar-height-xs;
}

.navbar-logo {
  position: absolute;
  top: 10px;
  left: 10px;
  max-width: 160px;
}

/* ==========================================================================
Navbar-nav
========================================================================== */
.navbar-nav {
  display: block;
  text-align: right;
  margin-right: 60px;

  .nav-link {
    @extend
      .p-0;

    @include transition();
    @include font-size(24);

    color: $white;
    line-height: 2.25rem;

    &::after {
      @extend .d-none;
    }

    &:hover,
    &:focus {
      color: $theme-secondary;
    }
  }

  .nav-item {
    @extend .ml-xxl-2;

    &.active {
      .nav-link {
        color: $theme-primary;
      }
    }

    &.show {
      .nav-link {
        color: $theme-primary;

        .svg-inline--fa {
          color: $theme-primary;
          transform: rotate(180deg);
        }
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .navbar-logo {
    top: 30px;
    left: 30px;
    max-width: 240px;
  }

  .navbar-nav {
    margin-right: 200px !important;

    .nav-link {
      @include font-size(30);

      line-height: 2.75rem;
    }
  }
}

@include media-breakpoint-up(xl) {
  .navbar-nav {
    margin-right: 300px !important;
  }
}
