/* ==========================================================================
Columns One
========================================================================== */
.columns-one--container {
  position: relative;
  background-color: $theme-primary;

  .container {
    @extend
      .py-5,
      .py-md-10;
  }

  .row {
    @extend .my-lg-6;

    justify-content: center;
  }

  .columns-one--content {
    @extend
      .py-6,
      .py-md-8,
      .px-3,
      .px-sm-0;

    color: $white;
    position: relative;
    text-align: center;
    z-index: 2;

    .btn {
      @extend .mt-2;

      color: $white;
    }
  }

  .columns-one--text {
    h1 {
      @extend .mb-2;
    }
  }

  .bottom-line {
    @extend .mt-3;
  }
}
