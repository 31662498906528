/* ==========================================================================
Logos
========================================================================== */
.logos--container {
  background-color: $theme-primary;

  .container {
    @extend
      .py-5,
      .py-md-10;

    text-align: center;
  }

  .logos--item {
    @extend
      .mb-2,
      .mb-md-0;

    display: flex;
    align-items: center;

    img {
      width: 100%;
    }
  }
}
