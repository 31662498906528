/* ==========================================================================
Variables - Must be loaded first
========================================================================== */
@import "_variables";
@import "helpers/_mixins";

/* ==========================================================================
Bootstrap - Must be loaded second
https://getbootstrap.com/
========================================================================== */
@import "_bootstrap";

/* ==========================================================================
Animate.css
https://github.com/daneden/animate.css
========================================================================== */
@import "~animate.css/animate.min.css";

/* ==========================================================================
Hamburgers
https://github.com/jonsuh/hamburgers
========================================================================== */
@import "~hamburgers/_sass/hamburgers/hamburgers";

/* ==========================================================================
Plyr
https://github.com/sampotts/plyr
========================================================================== */
// @import "~plyr/dist/plyr.css";

/* ==========================================================================
Swiper
https://swiperjs.com/
========================================================================== */
@import "~swiper/swiper-bundle.css";

/* ==========================================================================
Base
========================================================================== */
@import "base/_fonts";
@import "base/_global";
@import "base/_typography";

/* ==========================================================================
Components
========================================================================== */
@import "components/_modal";

/* ==========================================================================
Helpers
========================================================================== */
@import "helpers/_concrete5";
@import "helpers/_lazy";

/* ==========================================================================
Layouts (needs to be outsite wrapper)
========================================================================== */
@import "layouts/navbar/_navbar-collapse";

/* ==========================================================================
Page wrapper to prevent BS3/BS4 conflict in edit mode
========================================================================== */
.page-wrapper {
  /* ==========================================================================
  Blocks (Concrete5)
  ========================================================================== */
  // @import "blocks/_example";

  /* ==========================================================================
  Components
  ========================================================================== */
  @import "components/_buttons";
  @import "components/_cards";
  @import "components/_cocktails";
  @import "components/_columns-one";
  @import "components/_columns-two";
  @import "components/_formidable";
  @import "components/_hamburgers";
  @import "components/_hero";
  @import "components/_logos";
  @import "components/_social-media";
  @import "components/_switch-language";

  /* ==========================================================================
  Layouts
  ========================================================================== */
  @import "layouts/footer/_footer-columns";
  @import "layouts/footer/_footer-links";
  @import "layouts/footer/_footer";
  @import "layouts/navbar/_navbar-brand";
  @import "layouts/navbar/_navbar-dropdown";
  @import "layouts/navbar/_navbar";
  // @import "layouts/_header";

  /* ==========================================================================
  Page List+ (Concrete5)
  ========================================================================== */
  @import "page-list-plus/_page-list-plus";
}
