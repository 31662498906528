/* ==========================================================================
Hero
========================================================================== */
.hero {
  overflow: hidden;
  position: relative;
}

/* ==========================================================================
Hero block
========================================================================== */
.hero__block {
  &::before {
    @extend .w-100;

    bottom: 0;
    content: "";
    display: none;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;
  }

  .container {
    position: relative;
    z-index: 2;
  }

  .row {
    @extend .align-items-center;
  }
}

.hero--sound {
  align-items: flex-end;
  bottom: 0;
  color: #fff;
  display: flex;
  height: 100%;
  left: 0;
  padding: 20px;
  line-height: 16px;
  position: absolute;
  width: 100%;
  z-index: 5;

  &:hover,
  &:focus {
    text-decoration: none;
  }

  .fa-volume-off,
  .fa-volume-up {
    margin-right: 10px;
  }
}

/* ==========================================================================
Hero logo
========================================================================== */
.hero__logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  min-width: 50%;
  max-width: 500px;
  z-index: 3;
}

/* ==========================================================================
Hero image
========================================================================== */
.hero__image {
  @extend
    .h-100,
    .w-100;

  position: absolute;
  top: 0;
  z-index: 1;

  img {
    @extend .w-100;

    object-fit: cover;
    object-position: 50% 50%;
  }
}

/* ==========================================================================
Hero images
========================================================================== */
.hero__images {
  @extend
    .h-100,
    .w-100;

  position: absolute;
  top: 0;
  z-index: 1;
}

/* ==========================================================================
Hero title
========================================================================== */
.hero__title {
  p {
    @extend
      .h1,
      .mb-1;
  }
}

/* ==========================================================================
Hero subtitle
========================================================================== */
.hero__subtitle {
  p {
    @extend
      .h2,
      .mb-1;
  }
}

/* ==========================================================================
Hero text
========================================================================== */
.hero__text {
  p {
    @extend
      .mb-2;

    @include font-size(18);
  }
}

/* ==========================================================================
Hero buttons
========================================================================== */
.hero__buttons {
  font-size: 0;

  @include media-breakpoint-only(xs) {
    .btn {
      width: 100%;
    }
  }

  .btn {
    @extend
      .btn-theme-primary,
      .mb-1,
      .mb-sm-0;
  }

  .btn + .btn {
    @extend .ml-sm-3;
  }
}

/* ==========================================================================
Hero mode (dark / light)
========================================================================== */
.hero--mode-dark {
  background-color: $black;

  .hero__title,
  .hero__subtitle,
  .hero__text {
    p {
      color: $white;
    }
  }
}

.hero--mode-light {
  background-color: $white;

  .hero__title,
  .hero__subtitle,
  .hero__text {
    p {
      color: $black;
    }
  }
}

/* ==========================================================================
Hero align (center / left / right)
========================================================================== */
.hero--align-center {
  @extend .text-md-center;

  .row {
    @extend .justify-content-md-center;
  }

  &.hero--mode-dark {
    &::before {
      background: linear-gradient(180deg, rgba(0, 34, 134, 0) 0%, rgba(0, 34, 134, 1) 90%);
    }
  }

  &.hero--mode-light {
    &::before {
      background: linear-gradient(to right, rgba($white, 0) 0%, rgba($white, 1) 50%, rgba($white, 0) 90%);
    }
  }
}

.hero--align-left {
  @extend .text-md-left;

  .row {
    @extend .justify-content-md-start;
  }

  &.hero--mode-dark {
    &::before {
      background: linear-gradient(180deg, rgba(0, 34, 134, 0) 0%, rgba(0, 34, 134, 1) 90%);
    }
  }

  &.hero--mode-light {
    &::before {
      background: linear-gradient(to right, rgba($white, 1) 0%, rgba($white, 0) 90%);
    }
  }
}

.hero--align-right {
  @extend .text-md-right;

  .row {
    @extend .justify-content-md-end;
  }

  &.hero--mode-dark {
    &::before {
      background: linear-gradient(180deg, rgba(0, 34, 134, 0) 0%, rgba(0, 34, 134, 1) 90%);
    }
  }

  &.hero--mode-light {
    &::before {
      background: linear-gradient(to right, rgba($white, 0) 0%, rgba($white, 1) 90%);
    }
  }
}

/* ==========================================================================
Hero gradient
========================================================================== */
.hero--gradient-hide {
  &::before {
    @extend .d-none;
  }
}

.hero--gradient-show {
  &::before {
    @extend .d-block;
  }
}

/* ==========================================================================
Hero arrow
========================================================================== */
.hero__arrow {
  @include font-size(20);

  background-color: $theme-primary;
  border-radius: 10px;
  bottom: 20px;
  color: $white;
  height: $hero-arrow-size;
  line-height: $hero-arrow-size;
  left: 50%;
  position: absolute;
  text-align: center;
  transform: translateX(-50%);
  width: $hero-arrow-size;
  z-index: 3;
}

.hero--arrow-hide {
  .hero__arrow {
    @extend .d-none;
  }
}

.hero--arrow-show {
  .hero__arrow {
    @extend .d-block;
  }
}

/* ==========================================================================
Hero height (xs / sm / md / lg / xl / xxl)
========================================================================== */
.hero--height-xs {
  .row {
    min-height: $hero-height-xs;
  }

  .hero__image {
    img {
      height: $hero-height-xs;
    }
  }
}

.hero--height-sm {
  .row {
    min-height: $hero-height-sm;
  }

  .hero__image {
    img {
      height: $hero-height-sm;
    }
  }
}

.hero--height-md {
  .row {
    min-height: $hero-height-md;
  }

  .hero__image {
    img {
      height: $hero-height-md;
    }
  }
}

.hero--height-lg {
  .row {
    min-height: $hero-height-lg;
  }

  .hero__image {
    img {
      height: $hero-height-lg;
    }
  }
}

.hero--height-xl {
  .row {
    min-height: $hero-height-xl;
  }

  .hero__image {
    img {
      height: $hero-height-xl;
    }
  }
}

.hero--height-xxl {
  .row {
    min-height: $hero-height-xxl;
  }

  .hero__image {
    img {
      height: $hero-height-xxl;
    }
  }
}

@include media-breakpoint-up(lg) {
  .hero--height-xxl {
    .row {
      min-height: calc(100vh - #{$navbar-height-lg});
    }

    .hero__image {
      img {
        height: calc(100vh - #{$navbar-height-lg});
      }
    }
  }
}

/* ==========================================================================
Hero video
========================================================================== */
.hero__video {
  @extend
    .h-100,
    .w-100;

  position: absolute;
  top: 0;
  z-index: 1;

  video {
    height: auto;
    left: 50%;
    min-height: 100%;
    min-width: 100%;
    position: absolute;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: auto;
    z-index: 1;
  }
}
