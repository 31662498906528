/* ==========================================================================
Language flags
========================================================================== */
.language--flags {
  @extend .d-inline-flex;

  span {
    padding: 0 3px;
  }
}

/* ==========================================================================
Language flags list
========================================================================== */
.language--flags-list {
  @extend
    .d-flex,
    .justify-content-center,
    .mb-0,
    .p-0;

  list-style: none !important;
}

/* ==========================================================================
Language flags item
========================================================================== */
.language--flags-item {
  @extend
    .d-flex,
    .align-items-center;

  &:last-child {
    .language--flags-divider {
      @extend .d-none;
    }
  }
}

/* ==========================================================================
Language flags link
========================================================================== */
.language--flags-link {
  @include font-size(14px);
  @include transition();

  text-decoration: none;
  text-transform: uppercase;
}

/* ==========================================================================
Language flags active
========================================================================== */
.language--flags-active {
  font-weight: 700;
}
