/* ==========================================================================
Language list dark
========================================================================== */
.language--list-dark {
  .language--list-link {
    color: $body-color;

    &:focus,
    &:hover {
      color: $theme-primary;
    }
  }

  .language--list-divider {
    color: $body-color;
  }
}
