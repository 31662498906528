/* ==========================================================================
Buttons
========================================================================== */
.btn {
  background-color: $theme-secondary;
  border: 2px solid $theme-secondary;
  text-transform: uppercase;
  min-width: $btn-min-width;

  @include font-size(16);

  &:hover,
  &:focus {
    background-color: transparent;
    border: 2px solid $theme-secondary;
  }
}

.btn-white-outline {
  color: $white;
  border: 2px solid $white;
  background-color: $theme-primary;
}

/* ==========================================================================
Custom Block Buttons
========================================================================== */
.block--buttons {
  font-size: 0;

  .btn + .btn {
    @extend .ml-0;
    @extend .ml-sm-2;
    @extend .mt-2;
    @extend .mt-sm-0;
  }
}

@include media-breakpoint-down(xs) {
  .block--buttons {
    .btn {
      width: 100%;
    }
  }
}
