/* ==========================================================================
Footer columns
========================================================================== */
.footer-container {
  position: relative;
  background-color: $theme-primary;

  .container {
    height: 100%;

    .row {
      height: 100%;
      justify-content: flex-end;
      align-items: center;

      a {
        color: $white;
        text-decoration: none;

        @include transition();

        &:hover,
        &:focus {
          color: $theme-secondary;
        }
      }
    }

    .footer-img {
      @extend
        .mt-2,
        .mt-md-0;

      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 100%;
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  .footer-container {
    .container {
      .footer-img {
        img {
          max-height: 300px;
          width: auto;
        }
      }
    }
  }
}
